/****************************** ALL MEDIA QUERIES *********************************/

// Devices with a min-width of 992px

@media (min-width: 992px) {
  .holiday-here-logo {
      width: 235px;
  }

    // Custom Page Title
    .custom-page-title {
      h2 {
      font-size: 1.7rem;
      }
    }

    h1,
.h1 {
  font-size: $font-size-large;
}

h2,
.h2 {
  font-size: $font-size-medium;
}

h3,
.h3 {
  font-size: $font-size-medium-regular;
}

h4,
.h4 {
  font-size: $font-size-small;
}

h5,
.h5 {
  font-size: $font-size-smaller;
}

h6,
.h6 {
  font-size: $font-size-xsmall;
}

.main-nav {
  height: 65px;
}

.main-content-area {
  border-radius: 0.25rem;
  padding: 2rem 2.5rem;
  margin: 5rem auto;
  min-height: 760px;
}

    header.main-header {
      img {
        width: 200px;
      }
    }
    .navbar {
      padding: 0 1rem;
    }
    .nav-link {
      display: inline;
    }
    .form-control.site-search {
      border: rgba(175, 28, 135, 0.1);
    }
    .intro .intro-img {
      width: 75%;
      float: right;
    }
    .intro .intro-text {
      width: 60%;
      margin: 1.5rem auto;
    }
    .intro .intro-text .intro-button {
      width: 100%;
      left: 0;
      position: absolute;
      bottom: -2rem;
    }
    .intro .requestlogin-text {
      width: 45%;
      margin: 1.5rem auto;
    }
    .about-heading .about-heading-img {
      position: relative;
      z-index: 0;
    }
    .about-heading .about-heading-content {
      margin-top: -5rem;
      position: relative;
      z-index: 1;
    }
    .product-item .product-item-title {
      position: relative;
      z-index: 1;
      margin-bottom: -3rem;
    }
    .product-item .product-item-img {
      position: relative;
      z-index: 0;
      max-width: 60vw;
    }
    .product-item .product-item-description {
      position: relative;
      z-index: 1;
      margin-top: -3rem;
      max-width: 50vw;
    }
    .list-hours {
      width: 50%;
      font-size: 1.1rem;
    }
    .days-digits,
    .hours-digits,
    .minutes-digits {
      display: inline-block;
      font-size: 1.25rem;
    }
    .modal-dialog {
      max-width: 1200px !important;
    }
    .deal-card.deal-card-top-pick.card img {
      height: 370px;
    }
    .deal-carousel img {
      height: 500px;
    }
  }

  .btn-chat a {
    text-decoration: none;
    background: $primary-color;
    padding: 15px 20px 8px;
    display: block;
    font-weight: bold;
    font-size: 0.9rem;
    box-shadow: $custom-box-shadow;
    border-radius: 0.25rem;
    -ms-transform: rotate(90deg) translate(0, -20px);
    -webkit-transform: rotate(90deg) translate(0, -20px);
    transform: rotate(90deg) translate(0, -20px);
    position: relative;
    right: -27px;
    &:hover {
      right: -20px;
    }
  }
  
//   Devices with a min-width of 1200px
  
  @media (min-width: 1200px) {
    header.main-header {
      img {
        width: 235px;
      }
    }
    .ui.container {
      margin-top: 100px;
      margin-bottom: 85px;
    }
    .intro .intro-text {
      width: 100%;
    }
    .navbar-expand-xl .navbar-nav .nav-link {
      padding-right: 1.3rem;
      padding-left: .3rem;
    }
    .dropdown-menu {
      background-color: $primary-color;
      border: 0;
      border-left: 1px solid #dee2e6;
      border-bottom: 1px solid #dee2e6;
      border-radius: 0;
    }
    .dropdown-item {
      color: white;
      background-color: transparent;
      font-size: 15px;
      font-weight: 500;
      text-align: left;
      transition: 0.3s ease-in-out;
    }
    .dropdown-item.active,
    .dropdown-item:active {
      background-color: transparent;
    }
    ul.list-unstyled.card-list {
      display: -webkit-inline-box;
    }
  }

  .booking-form {
      margin: 2% 5%;
  }

  .viva-cart-container {
      position: -webkit-sticky;
      position: sticky;
      top: 110px;
  }

// Device with max width of 768px  
  @media (max-width: 768px) {
    .DateRangePickerInput_arrow_svg {
      height: 20px !important;
      width: 91px !important;
    }
  }

//  Specific to iPad in landscape 
  @media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) 
  and (orientation: landscape) {
    .navbar-nav {
      flex-direction: column;
    }
  }

//  Specific to iPad Pro in landscape
  @media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px) 
  and (-webkit-min-device-pixel-ratio: 2) 
  and (orientation: landscape) {
    .main-nav .navbar-nav .nav-link {
      font-size: $font-size-xsmall;
    }
    .main-nav .contact-nav span {
      bottom: 0;
    }
  }