body {
  font: 100% $font-stack;
  color: $black-opacity-80;
}

header.main-header {
  z-index: 99999;
  background: $white-opacity-70;
  img {
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0) !important;
    width: 80px;
    display: block;
    margin: 0 auto;
  }
}

.main-content-area {
  background-color: $white-opacity-70;
  border-radius: 0;
  padding: 2rem 1rem;
  margin: 0 auto;
  min-height: 760px;
}

// Navigation Elements
.main-nav {
  background-color: $primary-color;
  .navbar-nav .nav-link {
    color: white;
    font-size: 15px;
    opacity: 0.8;
    font-weight: 500;
    transition: 0.3s ease-in-out;
    &:hover {
      color: white;
      opacity: 1;
    }
  }
  .navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
    color: white;  
    opacity: 1; 
    font-weight: 600;
  }
  .contact-nav {
    background-color: $secondary-color;
    color: white;
    text-align: center;
    .ph-code {
      font-size: 2rem;
      padding-right: 3px;
    }
    .phoneword {
      font-size: 0.8rem;
      letter-spacing: 3px;
    }
    span.talkExpert {
      font-size: $font-size-xsmall;
      font-weight: normal;
      position: relative;
      letter-spacing: 1px;
      text-transform: initial;
    }
  }
}
.navbar-menu-mob {
  background-color: rgba(237, 28, 36, 1);
  text-align: center;
}
.dropdown-menu {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #dee2e6;
  border-radius: 0;
  margin: .125rem 0 0.9rem 0;
}
.dropdown-item {
  color: white;
  background-color: transparent;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  transition: 0.3s ease-in-out;
  padding: .5rem 1rem;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent;
  font-weight: 600;
}
/////////////////////////////////////

.footer {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $secondary-color;
  color: #fff;
  .social-links {
    background-color: $primary-color;
  }
}

#jumbo-journey {
  padding: 0;
  margin-bottom: 0rem;
  img {
    // min-height: 680px;
  }
}

.jumbotron {
  // min-height: 680px;
  margin-bottom: 0;
}

#livechat-compact-container {
  height: 0px !important;
  position: fixed;
  right: 0;
  top: 200px;
  top: 40vh;
  z-index: 999;
  color: white;
  &:hover {
    color: $black-opacity-80;
    cursor: pointer;
  }
}
