// Mobile first styles

@import url("https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400i,700&display=swap");

$font-stack: Montserrat, sans-serif;
$font-stack-caption: Libre Baskerville, serif;

$primary-color: #ed1c24;
$secondary-color: #015a94;
$black-opacity-80: rgba(0, 0, 0, 0.87);
$black-opacity-30: rgba(0, 0, 0, 0.3);
$white-opacity-70: rgba(255, 255, 255, 0.85);
$white-opacity-30: rgba(255, 255, 255, 0.3);
$placeholder: #1a082c;

$font-size-xlarge: 5rem;
$font-size-large: 3rem;
$font-size-medium: 2rem;
$font-size-medium-regular: 1.3rem;
$font-size-regular: 1rem;
$font-size-small: 0.9rem;
$font-size-smaller: 0.8rem;
$font-size-xsmall: 0.7rem;

$borders: 1px solid rgba(237, 237, 237, 0.3);
$custom-box-shadow: 0px 3px 6px 0px rgba(33, 37, 41, 0.1);
$letter-spacing: 3px;

.text-viva-red {
  color: $primary-color;
}

.bg-viva-red {
  background-color: $primary-color;
  color: white !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $black-opacity-80;
}

h1,
.h1 {
  font-size: $font-size-large;
}

h2,
.h2 {
  font-size: $font-size-medium;
}

h3,
.h3 {
  font-size: $font-size-regular;
}

h4,
.h4 {
  font-size: $font-size-small;
}

h5,
.h5 {
  font-size: $font-size-smaller;
}

h6,
.h6 {
  font-size: $font-size-xsmall;
}

.banner-home h3 {
  color: white !important;
}

a {
  text-transform: uppercase;
  font-weight: 600;
  opacity: 1;
  transition: 0.3s all;
}

a:hover {
  text-decoration: none;
  opacity: 0.8;
}

p {
  line-height: 1.75;
  font-size: $font-size-regular;
}

h3,
.h3 {
  font-size: $font-size-regular;
}

h4,
.h4 {
  font-size: $font-size-small;
}

h5,
.h5 {
  font-size: $font-size-smaller;
}

h6,
.h6 {
  font-size: $font-size-xsmall;
}

a {
  text-transform: uppercase;
  font-weight: 600;
  opacity: 1;
  transition: 0.3s all;
}

a:hover {
  text-decoration: none;
  color: black;
  opacity: 0.8;
}

p {
  line-height: 1.75;
  font-size: $font-size-regular;
}

span {
  float: none !important;
  &.journey-date {
    font-style: italic;
    font-size: 20px;
    float: none;
  }
}

.x-small {
  font-size: 65%;
}

ul {
  list-style-type: square;
}

// marketing asked to restore bullet points
// ul {
//   //list-style-type: none;
//   padding: 0;
//   li {
//     line-height: 1.6;
//     //text-indent: 6px;
//   }
// }

i {
  color: $primary-color;
}
i.fa-search {
  color: white;
  cursor: pointer;
  background-color: $primary-color;
}

i.fa-shopping-cart {
  color: white;
  cursor: pointer;
  background-color: $primary-color;
}
