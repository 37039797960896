// Custom Page Title
.custom-page-title {
  text-transform: capitalize;
  margin-bottom: 2rem;
  letter-spacing: 2px;
  border-left: 3px solid #ed1c24;
  h2 {
    font-size: 1rem;
    text-transform: uppercase;
    padding-left: 1.5rem;
  }
}

// Buttons
.btn-custom {
  background: $secondary-color;
  border: 1px solid $secondary-color;
  color: white;
  opacity: 1;
  transition: 0.2s ease-in-out;
  letter-spacing: $letter-spacing;
  font-family: $font-stack;
  font-size: $font-size-xsmall;
  &:hover {
    background: white;
    border: 1px solid white;
    color: $secondary-color;
  }
}
.btn-custom-clear {
  background: white;
  border: 1px solid white;
  color: $secondary-color;
  opacity: 1;
  transition: 0.2s ease-in-out;
  letter-spacing: $letter-spacing;
  font-family: $font-stack;
  font-size: $font-size-xsmall;
  &:hover {
    background: $secondary-color;
    border: 1px solid $secondary-color;
    color: white;
  }
}

.btn-book {
  background: $primary-color;
  border: 1px solid $primary-color;
  border-radius: 0;
  color: white;
  padding: 0.5rem 0.75rem;
  opacity: 1;
  transition: 0.2s ease-in-out;
  letter-spacing: $letter-spacing;
  font-size: large;
  font-family: $font-stack;
  height: 80px;
  box-shadow: 2px 2px 25px #333;
  &:hover {
    background: transparent;
    border: 1px solid $primary-color;
    color: $black-opacity-80;
  }
}

.reset {
  font-weight: bold;
  text-transform: none;
  margin: 0 auto;
  text-transform: uppercase;
  &:hover {
    cursor: pointer;
  }
}

.btn-primary:disabled {
  background-color: fade-in($color: #ed1c24, $amount: 0);
  border-color: fade-in($color: #ed1c24, $amount: 0);
  color: white;
}

// Badge
.badge-viva {
  background-color: $secondary-color;
  letter-spacing: 2px;
  font-size: $font-size-xsmall;
}



// Info Banner
.banner-home {
  background-color: rgba(26,8,44,0.8);
  color: white !important;
  font-family: $font-stack;
  line-height: 1.8;
  border-radius: 0.25rem;
  text-align: center;
  padding: 30px;
  height: auto;
  margin: 0 auto;
}

.banner-home h3 {
  color: white !important;
}

// Cards
.card-header {
  height: 80px;
}

.brochurecard {
  background-color: transparent;
  img {
    box-shadow: $custom-box-shadow;
    border-bottom-right-radius: 25px;
    height: auto;
  }
}

//   Journey List Cards
.fromPrice {
  letter-spacing: 1px;
  color: $primary-color;
span {
  font-size: $font-size-xsmall;
  text-transform: uppercase;
  padding-left: 5px;
  color: $black-opacity-80;
  letter-spacing: 0px;
}
}

.deal-card {
  transition: 0.3s ease-in-out;
}

.deal-card:hover {
  box-shadow: 2px 2px 25px lightslategrey;
}

.priceDuration {
  position: absolute;
  top: 10%;
  right: -5px;
  background: rgba(1,90,148,0.8);
  box-shadow: $custom-box-shadow;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
  color: white;
  padding: 3px 11px;
  z-index: 999;
  h2 {
    color: white;
    font-size: 1.6rem;
  }
}

.top-pick-banner {
  text-align: center;
  color: white;
  font-weight: bold;
  padding: 5px 0;
  background-color: $primary-color;
  box-shadow: $custom-box-shadow;
}

// Package Page Elements
.total-price {
  color: $black-opacity-80;
  text-transform: uppercase;
}

.destinationGuide {
  background-color: transparent;
  img {
    box-shadow: $custom-box-shadow;
    border-bottom-right-radius: 25px;
  }
}

.downloads {
  ul li a {
    font-size: 0.8rem;
    color: $secondary-color;
  }
  i {
    color: $secondary-color;
  }
}

.campaign-destination {
  a {
  color: $primary-color;
  font-size: $font-size-smaller;
  }
}

.deal-destination {
  color: $primary-color;
  font-size: $font-size-smaller;
}

.package-description {
  line-height: 1.7;
  font-size: 1.125rem;
}

.sale-dates {
  font-size: $font-size-smaller;
  line-height: 1.4;
}

.travel-dates {
  font-size: $font-size-smaller;
  line-height: 1.4;
  span {
    font-size: 0.7rem;
    font-weight: 500;
  }
}

.price-ribbon-secondary {
  background-color: rgba(1, 90, 148, 0.85);
  padding: 0.3rem;
  h4 {
    color: white;
    text-transform: initial;
    font-weight: bold;
  }
}

.priceNSavings {
    background: rgba(1, 90, 148, 1);
    box-shadow: 0px 3px 6px 0px rgba(33, 37, 41, 0.1);
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
    .saving-amount {
      background-color: $primary-color;
      padding: 0.7rem;
      font-size: 1.2rem;
      letter-spacing: 2px;
      color: white;
    }
    h2 {
      color: white;
    }
    span {
      font-size: $font-size-smaller;
      padding: 0;
      margin: 0;
      color: white;
    }
}

// Deal Page Tabs
a.nav-link.deal-tab.nav-link {
    font-size: 13px;
    padding: 0.1rem 1rem;
}
a.nav-link.deal-tab.nav-link.active {
    background-color: $primary-color;
    color: white;
    border-radius: 0;
}
a.nav-link.deal-tab.nav-link {
    color: $primary-color;
}
.package-includes ul {
    list-style-type: square;
    font-size: $font-size-regular;
}
// Deal Page Tabs

// Ribbon
.card-title.pricing-card-title {
  position: absolute;
  top: -30px;
  right: 20px;
  background: var(--brand-one);
  line-height: 0.8em;
  font-size: 2em;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
  letter-spacing: -2px;
  display: block;
  width: 6rem;
  height: 5rem;
  color: white;
  margin: 1em 0.5em 0;
  float: left;
  padding-top: 1rem;
  padding-bottom: 1rem;
  -webkit-filter: drop-shadow(0 0.5rem 0.3em rgba(0, 0, 0, 0.5));
  transform: translate3d(0, 0, 0);
  h6 {
    padding: 0;
    margin: 0;
    color: white;
    line-height: 0.1 !important;
    letter-spacing: 0;
  }
}
.card-title.pricing-card-title:after {
  content: "";
  width: 0;
  height: 0;
  border-right: 3rem solid transparent;
  border-left: 3rem solid transparent;
  border-top: 1.5rem solid $primary-color;
  position: absolute;
  top: 5rem;
  left: 0;
}

.card-body {
  padding: 1.25rem 1.25rem 0.25rem 1.25rem;
}

.card-branding {
  display: none;
  position: absolute;
  right: 7px;
  bottom: 7px;
  height: 50px;
}

.card-urgency {
  color: var(--global-red);
  padding: 3px 3px;
  width: auto;
  font-size: 0.75rem;
  font-weight: bold;
  vertical-align: middle;
}

.discover-journeys {
  .card-header {
    position: absolute;
    right: 8px;
    bottom: 8px;
    left: 8px;
    background-color: $black-opacity-30;
  }
}

.card img.getaway-image {
  width: 100%;
  max-height: 170px;
}

.fit-content-image img.getaway-image {
  max-height: fit-content;
}

.social-share-options {
  border-bottom: 1px solid var(--brand-one);
}

.conditions p {
  font-size: 0.75rem !important;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;
}

.booksold {
  text-transform: uppercase;
  opacity: 1;
  letter-spacing: 1px;
}

.btn-booksold:hover {
  background-color: #af1c87 !important;
  color: white !important;
}

/////////////////////////////////////


#subscription button {
  background-color: $primary-color;
  color: white;
  border: 0px solid $primary-color;
  border-radius: 4px;
  box-shadow: $custom-box-shadow;
  height: 37px;
  font-size: $font-size-regular;
  letter-spacing: 3px;
  transition: 0.3s ease-in-out;
  &:hover {
    color: $black-opacity-80;
  }
}

/////////////////////////////////////

// Horizontal Rules
.hr-short-50 {
  border-top: 1px solid $secondary-color;
  width: 50%;
  margin: 1.5rem auto !important;
}

/////////////////////////////////////

// Forms 

  .journey-filter {
    background-color: $primary-color;
    .form-group {
      margin-bottom: 0rem;
      border-radius: 0rem;
      &:hover {
        cursor: pointer;
      }
    }
    .form-control {
      height: 67px;
      width: 70%;
      text-transform: uppercase;
      font-weight: 600;
      font-size: $font-size-regular;
      letter-spacing: 3px;
      border: 0px solid $primary-color;
      background-color: transparent;     
      color: white;
    }
  }

  .form-check-input {
    margin-top: 0.2rem;
  }

  a.signup-privacy-link {
    color: white;
    text-transform: lowercase;
  }
  
  .sign-up-home {
    background-color: rgba(255, 255, 255, 0.9);
  }
  
  .email-sign-up {
    border: 1px solid $secondary-color;
    box-shadow: 0px 3px 6px 0px rgba(33, 37, 41, 0.1);
    padding: 0.5rem 0.75rem !important;
    border-radius: 0.25rem;
    line-height: 1.5;
    font-weight: 400;
    color: $placeholder !important;
    text-align: left;
    &::placeholder {
      color: $placeholder;
    }
    &:placeholder-shown {
      color: $placeholder;
    }
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $placeholder;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: $placeholder;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: $placeholder;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: $placeholder;
    }
  }
  
  .fas {
    line-height: 1.2 !important;
  }
  
  .form-control {
    font-size: 0.75rem;
    height: 48px;
  }
  
  .form-control.site-search {
    font-size: 1.25rem;
    text-transform: uppercase;
    background-color: transparent;
    color: #ffffff;
    border: 0;
  }
  
  .form-control.site-search::placeholder {
    color: #ffffff;
    letter-spacing: 1px;
  }
  
  .form-control:focus {
    border-color: rgba(175, 28, 135, 0.1);
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  
  .DayPickerInput {
    display: block !important;
    input {
      font-size: 0.75rem;
      height: 48px;
      border-radius: 0.25rem;
      border: 1px solid #dee2e6;
      width: 100%;
      padding: 0.375rem 0.75rem;
    }
  }

  .DateInput_input {
    font-size: 0.75rem !important;
    color: #495057 !important;
    font-weight: 400 !important;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }

  .DayPickerInput-OverlayWrapper {
    z-index: 99999 !important;
  }

  .DateRangePickerInput_arrow_svg {
    height: 20px !important;
    width: 41px !important;
  }
  
  /////////////////////////////////////

//   Inclusions List Style

  .package-inclusions ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    li {
      position: relative;
      display: block;
      padding: 0.75rem 1.25rem;
      margin-bottom: -1px;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-right: 0;
      border-left: 0;
      border-radius: 0;
    }
  }
  /////////////////////////////////////

//   Site Heading

  .site-heading {
    margin-top: 5rem;
    margin-bottom: 5rem;
    text-transform: uppercase;
    line-height: 1;
    font-family: "Montserrat", sans-serif;
    color: #756f77;
    font-weight: 300;
    .site-heading-upper {
      display: block;
      font-size: 2rem;
      font-weight: 800;
    }
    .site-heading-lower {
      font-size: 5rem;
      font-weight: 100;
      line-height: 4rem;
    }
  }
  /////////////////////////////////////
  
//   Sections

  .page-section {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  
  .section-heading {
    text-transform: capitalize;
  }
  
  .section-heading .section-heading-upper {
    display: block;
    font-size: 1rem;
    font-weight: 400;
  }
  
  .section-heading .section-heading-lower {
    display: block;
    font-size: 3rem;
    font-weight: 100;
  }
  /////////////////////////////////////

  
//   Destinations
  .destination-guide-link {
  i,
  a {
    color: $primary-color;
    font-size: 0.9rem;
    text-transform: capitalize;
    letter-spacing: 2px;
    }
  }

  .destinations-country-list {
    .card-header {
        position: absolute;
        right: 15px;
        bottom: 0px;
        left: 15px;
        background-color: $black-opacity-30;
        height: auto;
    }
  }
  .destinations-list {
    .card {
      transition: 0.3s ease-in-out;
      &:hover {
        cursor: pointer;
        box-shadow: 2px 2px 25px lightslategrey;
      }
    }
    .card-header {
      position: absolute;
      right: 8px;
      bottom: 8px;
      left: 8px;
      background-color: $black-opacity-30;
    }
    .deal-card {
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    }
    img {
      box-shadow: none !important;
    } 
  }
  
  /////////////////////////////////////
  
  .address strong {
    font-size: 1.2rem;
  }

//   Pax Details
  
  .pax-detail-summary p {
    font-size: 0.75rem;
  }
  
  /////////////////////////////////////

// Booking form

.cost-tally-bar {
	background-color: $primary-color;
	font-size: x-large;
	color: white;
	padding: 0.8rem;
}

//   Booking Confirmation
  
  .card-confirmed {
    padding: 3px 7px;
  }

  .viva-cart {
    background-color: white;
    box-shadow: $custom-box-shadow;
    // max-width: 30%;
  }

  .cart-content {
      font-size: 70%;
  }
  
  /////////////////////////////////////

  
//   Carousel
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 50px;
    height: 50px;
  }
  .carousel-caption {
    top: 50%;
    right: 0;
    bottom: 20px;
    left: 0;
    font: 100% $font-stack-caption;
    h2 {
      font-size: $font-size-xlarge;
      text-transform: uppercase;
      color: white;
      text-shadow: 0 3px 3px $black-opacity-80;
    }
    h3 {
      color: white;
      font-style: italic;
      font-weight: 200 !important;
      font-size: $font-size-medium;
    }
  }

  /////////////////////////////////////

  
//   Accordian with Font Awesome icons
  .faq-answer {
    font-size: 1.15rem !important;
  }
  /////////////////////////////////////
  
  
//   Sold Out Ribbon
  
  .sold-out-ribbon {
    position: absolute;
    top: -20px;
    left: 24px;
    background: var(--brand-two);
    line-height: 0.85em;
    font-size: 1.3em;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
    display: block;
    width: 6rem;
    height: 5rem;
    color: white;
    margin: 1em 0.5em 0;
    float: left;
    padding-top: 1rem;
    padding-bottom: 1rem;
    -webkit-filter: drop-shadow(0 0.5rem 0.3em rgba(0, 0, 0, 0.5));
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    ::after {
      content: "";
      width: 0;
      height: 0;
      border-right: 3rem solid transparent;
      border-left: 3rem solid transparent;
      border-top: 1.5rem solid var(--brand-two);
      position: absolute;
      top: 5rem;
      left: 0;
    }
    small.similar-deals-text {
      text-transform: none !important;
    }
  }
  
  .sidebar-deals .corner-ribbon .sold-out-message {
    display: none;
  }
  
  .sidebar-deals .corner-ribbon {
    width: 150px;
  }
  
  .sidebar-deals .corner-ribbon.top-left {
    top: 45px;
  }
  
  .corner-ribbon {
    width: 210px;
    background: #e43;
    position: absolute;
    top: 25px;
    left: -50px;
    text-align: center;
    line-height: 17px;
    letter-spacing: 1px;
    color: #f0f0f0;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  
  .corner-ribbon span {
    color: #ddd;
    text-transform: none;
  }
  
  .corner-ribbon.sticky {
    position: absolute;
  }
  
  .corner-ribbon.shadow {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  }
  
  .corner-ribbon.top-left {
    top: 65px;
    left: -22px;
    padding: 3px 1px 3px 1px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  
  .corner-ribbon.top-right {
    top: 25px;
    right: -50px;
    left: auto;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  
  .corner-ribbon.bottom-left {
    top: auto;
    bottom: 25px;
    left: -50px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  
  .corner-ribbon.bottom-right {
    top: auto;
    right: -50px;
    bottom: 25px;
    left: auto;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  /////////////////////////////////////

  // Logos

  .secondary-logo {
    position: absolute;
    top: 0;
    right: 0;
    width: 120px;
  }

  .footer-logos {
    background-color: transparent;
    border: 0;
    img {
      height: 40px;
      box-shadow: none !important;
    }
  }

  .brand-logos {
    margin: 0 auto !important;
    background-color: transparent;
    border: 0;
    img {
      height: 80px;
      box-shadow: none !important;
    }
  }

  /////////////////////////////////////

  // Hosts

  #ultimate-hosts {
    img {
      height: 300px;
    }
  }

  /////////////////////////////////////

  // Slick Slider
  .slick-slider {
    margin-bottom: 0;
    padding: 10px;
    background-color: $white-opacity-30;
    border-radius: 0.25rem;
    box-shadow: $custom-box-shadow;
  }
  .slick-prev:before, .slick-next:before {
    color: $primary-color;
  }
  .slick-dots {
    li button:before {
      color: $primary-color;
    }
    li.slick-active button:before {
      color: $primary-color;
    }

  }
